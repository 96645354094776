import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import Config from '../../common/config';
import * as helpers from '../../common/helpers';
import Loading from '../core/loading';
import NotFound from '../core/notfound';
import Pagination from '../../components/core/pagination';

export default class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            leaderboardType: this.props.match.params.leaderboardType,
            pageNumber: this.props.match.params.pageNumber ? this.props.match.params.pageNumber : 1,
        };
    }

    componentDidMount() {
        this.fetchNewData();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isLoaded: false,
            leaderboardType: nextProps.match.params.leaderboardType,
            pageNumber: nextProps.match.params.pageNumber ? nextProps.match.params.pageNumber : 1
        }, () => {
            this.fetchNewData();
        });
    }

    fetchNewData() {
        let pageNumber = this.state.pageNumber == null ? 1 : this.state.pageNumber;

        fetch(Config.apiBaseUrl + `leaderboard/${this.state.leaderboardType}/page/${pageNumber}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        leaderboard: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            ).then(() => {
            window.scrollTo(0, 0);
        })
    }

    render() {
        const { error, isLoaded, leaderboard, leaderboardType, pageNumber } = this.state;
        let incrementer = 1;
        if(pageNumber > 1){
            incrementer = (50 * (pageNumber-1)) + 1;
        }

        if (error) {
            return <NotFound message={error.message} />
        } else if (!isLoaded) {
            return <Loading />;
        } else {
            return (
                <>
                    <h4>Leaderboard - All Time</h4>
                    <br />
                    <Table>
                        <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Kills</th>
                            <th>Kills GP</th>
                        </tr>
                        </thead>
                        <tbody>
                        {leaderboard.map((player, index) => (

                            <tr key={player.id}>

                                <td>
                                    {index + incrementer}
                                </td>

                                <td>
                                    <Link to={`/players/${player.name}`}>{player.name}</Link>
                                </td>

                                <td>
                                    {player.kills}
                                </td>

                                <td>
                                    {helpers.getShortFormattedNumber(player.killsGp)}
                                </td>

                            </tr>

                        ))}
                        </tbody>
                    </Table>

                    <Pagination
                        baseUrl={`/leaderboard/${leaderboardType}/page/`}
                        pageNumber={pageNumber}
                        pageCountUrl={`leaderboard/pages/`}
                    />
                </>
            );
        }
    }
}