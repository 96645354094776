import React from 'react';
import { Table } from 'react-bootstrap';

import * as helpers from '../../common/helpers';
import NotFound from '../core/notfound';

export default class Loot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loot: this.props.loot,
      lootValue: this.props.lootValue
    };
  }

  render() {
    const { error, loot, lootValue } = this.state;
    let sortedLoot = loot.sort((a, b) => b.price - a.price);

    if (error) {
      return <NotFound message={error.message} />
    } else {
      return (
        <>
          <h4>Loot</h4>
          <Table className='smalltable'>
            <thead>
              <tr>
                <th>Name</th>
                <th className='textRight'>Qty</th>
                <th className='textRight'>Value</th>
              </tr>
            </thead>
            <tbody>
              {sortedLoot.map(item => (
                <tr key={item.id}>
                  <td>
                    <img className='table' src={helpers.getImageForItemId(item.id)} alt={item.name} />
                    {item.name}
                  </td>
                  <td className='textRight'>{helpers.getFormattedNumber(item.qty)}</td>
                  <td className='textRight'>{helpers.getFormattedNumber(item.price)}</td>
                </tr>
              ))}

              <tr>
                <td></td>
                <td className='textRight'><b>Value:</b></td>
                <td className='textRight'><b>{helpers.getFormattedNumber(lootValue)}</b></td>
              </tr>
            </tbody>
          </Table>
        </>
      );
    }
  }
}