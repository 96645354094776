import React from 'react';
import { Link } from 'react-router-dom';
import Config from "../../common/config";

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            baseUrl: this.props.baseUrl,
            pageCountUrl: this.props.pageCountUrl,
            pageNumber: this.props.pageNumber,
            totalPages: 0,
        };
    }

    componentDidMount() {
        this.fetchNewData();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
        }, () => {
            this.fetchNewData();
        });
    }

    fetchNewData() {
        console.log(Config.apiBaseUrl + this.state.pageCountUrl);
        fetch(Config.apiBaseUrl + this.state.pageCountUrl)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    totalPages: result
                });
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        );
    }

    render() {
        const { baseUrl, pageNumber, totalPages } = this.state;
        let pageNumberInt = parseInt(pageNumber);

        let pageList = [];

        if(pageNumberInt <= 1){
            pageList.push(pageNumberInt);
            pageList.push(pageNumberInt + 1);
            pageList.push(pageNumberInt + 2);
            pageList.push(pageNumberInt + 3);
            pageList.push(pageNumberInt + 4);
        } else if (pageNumberInt <= 2){
            pageList.push(pageNumberInt - 1);
            pageList.push(pageNumberInt);
            pageList.push(pageNumberInt + 1);
            pageList.push(pageNumberInt + 2);
            pageList.push(pageNumberInt + 3);
        } else {
            pageList.push(pageNumberInt - 2);
            pageList.push(pageNumberInt - 1);
            pageList.push(pageNumberInt);
            pageList.push(pageNumberInt + 1);
            pageList.push(pageNumberInt + 2);
        }
        // console.log("STARTING PAGE LIST: " + pageList)

        pageList = pageList.filter(function(x){ return x > -1 });
        // console.log("FILTERED PAGE LIST: " + pageList)

        let finalPageList = [];
        pageList.forEach(element => {
            if(element <= totalPages){
                finalPageList.push(element);
            }
        })
        
        //console.log("FINAL PAGE LIST: " + finalPageList)

        return (
            <nav className='d-flex justify-content-center'>
                <ul className='pagination'>
                    {finalPageList.map((value, index) => {
                        return <li key={index} className='page-item'><Link className='page-link active' to={`${baseUrl}${value}`}>{value}</Link></li>
                    })}
                </ul>
            </nav>
        );
    }
}