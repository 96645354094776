import React from 'react';
import { Link } from 'react-router-dom';

import Loading from '../core/loading';
import NotFound from '../core/notfound';
import * as helpers from '../../common/helpers';
import Config from '../../common/config';

export default class BigKills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      kills: null,
      title: 'Most Valuable PKs - Last 7 Days',
      pageBaseUrl: 'pks/top/weekly',
    };
  }

  componentDidMount() {
    this.fetchTopKills()
  }

  componentWillReceiveProps(nextProps) {
    this.fetchTopKills()
  }

  fetchTopKills(){
    var pageBaseUrl = 'pks/top/weekly';
    var title = 'Most Valuable PKs - Last 7 Days'

    if (window.location.pathname.startsWith("/dmm")) {
      title= 'Most Valuable DMM PKs - Last 7 Days'
      pageBaseUrl = 'dmm/top/weekly';
    }

    this.setState({
      isLoaded: false,
      title: title,
      pageBaseUrl: pageBaseUrl,
    }, () => {
      this.fetchNewData();
    });
  }

  fetchNewData() {
    fetch(Config.apiBaseUrl + this.state.pageBaseUrl)
        .then(res => res.json())
        .then(
            (result) => {
              this.setState({
                isLoaded: true,
                kills: result
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
        )
  }

  render() {
    const { error, isLoaded, title, kills } = this.state;
    if (error) {
      return <NotFound message={error.message} />;
    } else if (!isLoaded) {
      return <Loading />;
    } else {
      let top5Kills = kills.slice(0, 6);
      return (
        <>
          <h4>{title}</h4>

          <div className='container bg-dark reduced-padding'>
            <div className='row'>
              {top5Kills.map(kill => (
                <div  key={kill.id} className='col col-lg-2 col-sm-2'>
                  <div className='text-center py-2'>

                    <Link to={`/pks/${kill.id}`}>
                      <img
                          className='bigImage'
                          src={helpers.getImageByIdForGearSlot(kill?.victimItemsEquipped?.weapon?.id, 'weapon')}
                          alt={kill?.victimItemsEquipped?.weapon?.name}
                          onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}}
                      />
                    </Link>

                    <br />

                    <Link to={`/pks/${kill.id}`}>{helpers.getFormattedNumber(kill.lootValue)}</Link>

                    <br />

                    <Link to={`/players/${kill.victimName}`}>{kill.victimName}</Link>
                    
                  </div>
                </div>
              ))}
            </div>
          </div>

        </>
      );
    }
  }
}