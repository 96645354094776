import React from 'react';

export default class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: this.props.message
        };
    }

    render() {
        const { message } = this.state;
        return (
            <div className='card'>
                <div className='card-body'>
                    <div className='container'>
                        <div className='txt-grey text-center py-2'>
                            <img className='' alt='' src='https://cdn.osrskillboard.com/assets/favicon-large.png' />
                        </div>
                        <div className='txt-grey text-center py-2'>
                            {message == null ? (
                                <h4>404 - Page not found.</h4>
                            ) : (
                                <p>{message}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}