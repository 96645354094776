import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import Config from '../../common/config';
import * as helpers from '../../common/helpers';
import Loading from '../../components/core/loading';
import NotFound from '../../components/core/notfound';
import Pagination from '../../components/core/pagination';

import BigKills from './bigkills';

export default class Killboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      kills: null,
      pageNumber: this.props.match.params.pageNumber ? this.props.match.params.pageNumber : 1,
      title: 'Most Recent PKs',
      targetUrl: 'pks',
      pageCountUrl: 'pks/page/pages',
      pageBaseUrl: '/pks/page/',
    };
  }

  componentDidMount() {
    var pageTitle = 'Most Recent Pks';
    var targetUrl = 'pks';
    var pageCountUrl = 'pks/page/pages';
    var pageBaseUrl = '/pks/page/';

    if(this.props.match.params.locationName){
      pageTitle = this.props.match.params.locationName;
      targetUrl = 'pks/location/' + this.props.match.params.locationName;
      pageCountUrl = 'pks/location/' + this.props.match.params.locationName + '/pages';
      pageBaseUrl = '/location/' + this.props.match.params.locationName + '/page/';
    }
    else if (window.location.pathname.startsWith("/dmm")) {
      pageTitle = 'Deadman Mode';
      targetUrl = 'dmm';
      pageCountUrl = 'dmm/pages';
      pageBaseUrl = '/dmm/page/';
    }

    this.setState({
      isLoaded: false,
      pageNumber: this.props.match.params.pageNumber ? this.props.match.params.pageNumber : 1,
      title: pageTitle,
      targetUrl: targetUrl,
      pageCountUrl: pageCountUrl,
      pageBaseUrl: pageBaseUrl,
    }, () => {
      this.fetchNewData();
    })
  }

  componentWillReceiveProps(nextProps) {
    var pageTitle = 'Most Recent Pks';
    var targetUrl = 'pks';
    var pageCountUrl = 'pks/page/pages';
    var pageBaseUrl = '/pks/page/';

    if(nextProps.match.params.locationName){
      pageTitle = nextProps.match.params.locationName;
      targetUrl = 'pks/location/' + nextProps.match.params.locationName;
      pageCountUrl = 'pks/location/' + nextProps.match.params.locationName + '/pages';
      pageBaseUrl = '/location/' + nextProps.match.params.locationName + '/page/';
    }
    else if (window.location.pathname.startsWith("/dmm")) {
      pageTitle = 'Deadman Mode';
      targetUrl = 'dmm';
      pageCountUrl = 'dmm/pages';
      pageBaseUrl = '/dmm/page/';
    }

    this.setState({
      isLoaded: false,
      pageNumber: nextProps.match.params.pageNumber ? nextProps.match.params.pageNumber : 1,
      title: pageTitle,
      targetUrl: targetUrl,
      pageCountUrl: pageCountUrl,
      pageBaseUrl: pageBaseUrl,
    }, () => {
      this.fetchNewData();
    });
  }

  fetchNewData() {
    let pageNumber = this.state.pageNumber == null ? 1 : this.state.pageNumber;
    
    fetch(Config.apiBaseUrl + `${this.state.targetUrl}/page/${pageNumber}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            kills: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      ).then(() =>{
        window.scrollTo(0, 0);
      })
  }

  render() {
    const { error, isLoaded, kills, pageNumber, title, pageCountUrl, pageBaseUrl } = this.state;

    if (error) {
      return <NotFound message={error.message}/>;
    } else if (!isLoaded) {
      return <Loading />;
    } else {
      return (
        <>

          <BigKills />

          <br />

          <h4>{title}</h4>
          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Value</th>
                <th>Victim</th>
                <th className='d-none d-md-block pixel-fix'>Pker</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {kills.map((kill, index) => (
                
                // Render Date Rows
                <React.Fragment key={kill.id}> 
                  {(index === 0) && (
                    <tr className='dateRow'>
                      <td colSpan='5'>{helpers.getFormattedDate(kill.time)}</td>
                    </tr>
                  )} 
                  {(index !== 0) && (helpers.getFormattedDate(kills[index - 1].time) !== helpers.getFormattedDate(kill.time)) && (
                    <tr className='dateRow'>
                      <td colSpan='5' >{helpers.getFormattedDate(kill.time)}</td>
                    </tr>
                  )}

                  <tr key={kill.id} style={kill.pkerName === 'Moonswatch' ? { backgroundColor: '#181432' } : {}}>
                    <td>
                      {helpers.getFormattedTime(kill.time)}
                    </td>

                    <td>
                      <Link to={`/pks/${kill.id}`}>{helpers.getShortFormattedNumber(kill.lootValue)}</Link>
                    </td>

                    <td>
                      <Link to={`/pks/${kill.id}`}>
                        <img
                             className='table'
                             src={helpers.getImageByIdForGearSlot(kill?.victimItemsEquipped?.weapon?.id, 'weapon')}
                             alt={kill?.victimItemsEquipped?.weapon?.name}
                             onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}}
                        />
                      </Link>
                      <Link to={`/players/${kill.victimName}`}>{kill.victimName}</Link>
                    </td>

                    <td className='d-none d-md-block pixel-fix'>
                      <Link to={`/pks/${kill.id}`}>
                        <img
                            className='table'
                            src={helpers.getImageByIdForGearSlot(kill?.pkerItemsEquipped?.weapon?.id, 'weapon')}
                            alt={kill?.pkerItemsEquipped?.weapon?.name}
                            onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}}
                        /></Link>
                      <Link to={`/players/${kill.pkerName}`}>{kill.pkerName}</Link>
                    </td>

                    <td className='dmm-column'>
                      {(helpers.isDeadmanKill(kill)) && (
                          <Link to={`/dmm`}><img className='table' src='https://cdn.osrskillboard.com/assets/dmm-icon-large.png' alt={"DMM"} /></Link>
                      )}
                    </td>

                  </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </Table>
          
          <Pagination baseUrl={pageBaseUrl} pageNumber={pageNumber} pageCountUrl={pageCountUrl} />
        </>
      );
    }
  }
}