import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import Config from '../../common/config';
import Loading from '../../components/core/loading';
import NotFound from '../../components/core/notfound';


export default class MostKills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false
    };
  }

  componentDidMount() {
    fetch(Config.apiBaseUrl + `pks/mostkills/weekly`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            mostKills: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, mostKills } = this.state;
    if (error) {
      return <NotFound message={error.message}/>
    } else if (!isLoaded) {
      return <Loading />;
    } else {
      return (
        <>
          <h4>Most Kills</h4>
          <Table className='smalltable'>
            <thead>
              <tr>
                <th>Player</th>
                <th className='textRight'>Kills</th>
              </tr>
            </thead>
            <tbody>
              {mostKills.map(pker => (
                <tr key={pker.name}>
                  <td>
                    <Link to={`/players/${pker.name}`}>{pker.name}</Link>
                  </td>
                  <td className='textRight'>{pker.number}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      );
    }
  }
}