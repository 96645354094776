import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as helpers from '../../common/helpers';
import NotFound from '../core/notfound';
import Config from '../../common/config';
import './playerinfo.css';

export default class PlayerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      gear: this.props.gear,
      playerName: this.props.playerName,
      playerCombat: this.props.playerCombat,
      playerStats: this.props.playerStats,
      playerGearStats: this.props.playerGearStats,
      isPker: this.props.isPker
    };
  }

  render() {
    const { error, gear, playerName, playerCombat, playerStats, playerGearStats, isPker } = this.state;

    if (error) {
      return <NotFound message={error.message}/>
    }
    else if(playerName === null || playerName === "") {
        return <NotFound message={"Pk not found"} />
    }
    else {
      return (
        <>
          {isPker ? (
            <h4>Pker - <Link className='no-align' to={`/players/${playerName}`}>{playerName}</Link></h4>
          ) : (
              <h4>Victim - <Link className='no-align' to={`/players/${playerName}`}>{playerName}</Link></h4>
            )}
          <Table>
            <thead>
              <tr className='stats'>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/combat.png'} alt='Combat Level' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/hitpoints.png'} alt='Hitpoints Level' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/attack.png'} alt='Attack Level' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/strength.png'} alt='Strength Level' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/defence.png'} alt='Defence Level' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/prayer.png'} alt='Prayer Level' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/magic.png'} alt='Magic Level' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/range.png'} alt='Ranged Level' /></td>
              </tr>
            </thead>
            <tbody>
              <tr className='stats'>
                <td>{playerCombat ? playerCombat : '?'}</td>
                <td>{playerStats && playerStats.hitpoints ? playerStats.hitpoints : '?'}</td>
                <td>{playerStats && playerStats.attack ? playerStats.attack : '?'}</td>
                <td>{playerStats && playerStats.strength ? playerStats.strength : '?'}</td>
                <td>{playerStats && playerStats.defence ? playerStats.defence : '?'}</td>
                <td>{playerStats && playerStats.prayer ? playerStats.prayer : '?'}</td>
                <td>{playerStats && playerStats.magic ? playerStats.magic : '?'}</td>
                <td>{playerStats && playerStats.ranged ? playerStats.ranged : '?'}</td>
              </tr>
            </tbody>
          </Table>

          <Table>
            <tbody className='playerinfo'>
              <tr>
                <td></td>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(gear.head.id, 'head')} alt={gear.head.name} title={gear.head.name} onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
                <td></td>
              </tr>
              <tr>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(gear.cape.id, 'cape')} alt={gear.cape.name} title={gear.cape.name} onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(gear.amulet.id, 'amulet')} alt={gear.amulet.name} title={gear.amulet.name} onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(null, 'ammo')} alt='Ammo Slot' title='Ammo Slot' onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
              </tr>
              <tr>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(gear.weapon.id, 'weapon')} alt={gear.weapon.name} title={gear.weapon.name} onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(gear.torso.id, 'torso')} alt={gear.torso.name} title={gear.torso.name} onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(gear.shield.id, 'shield')} alt={gear.shield.name} title={gear.shield.name} onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
              </tr>
              <tr>
                <td></td>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(gear.legs.id, 'legs')} alt={gear.legs.name} title={gear.legs.name} onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
                <td></td>
              </tr>
              <tr>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(gear.hands.id, 'hands')} alt={gear.hands.name} title={gear.hands.name} onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(gear.boots.id, 'boots')} alt={gear.boots.name} title={gear.boots.name} onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
                <td><img className='table equipment' src={helpers.getImageByIdForGearSlot(null, 'ring')} alt='Ring Slot' title='Ring Slot' onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}} /></td>
              </tr>
            </tbody>
          </Table>

          <Table>
            <thead>
              <tr className='stats'>
                <td></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/stab.png'} alt='Stab bonus' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/slash.png'} alt='Slash bonus' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/crush.png'} alt='Crush bonus' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/magic.png'} alt='Magic bonus' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/range.png'} alt='Range bonus' /></td>
              </tr>
            </thead>
            <tbody>
              <tr className='stats'>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/attack.png'} alt='Attack bonus' /></td>
                <td>{playerGearStats && playerGearStats.attack_stab !== null ? playerGearStats.attack_stab : '?'}</td>
                <td>{playerGearStats && playerGearStats.attack_slash !== null ? playerGearStats.attack_slash : '?'}</td>
                <td>{playerGearStats && playerGearStats.attack_crush !== null ? playerGearStats.attack_crush : '?'}</td>
                <td>{playerGearStats && playerGearStats.attack_magic !== null ? playerGearStats.attack_magic : '?'}</td>
                <td>{playerGearStats && playerGearStats.attack_ranged !== null ? playerGearStats.attack_ranged : '?'}</td>
              </tr>
              <tr className='stats'>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/defence.png'} alt='Defence bonus' /></td>
                <td>{playerGearStats && playerGearStats.defence_stab !== null ? playerGearStats.defence_stab : '?'}</td>
                <td>{playerGearStats && playerGearStats.defence_slash !== null ? playerGearStats.defence_slash : '?'}</td>
                <td>{playerGearStats && playerGearStats.defence_crush !== null ? playerGearStats.defence_crush : '?'}</td>
                <td>{playerGearStats && playerGearStats.defence_magic !== null ? playerGearStats.defence_magic : '?'}</td>
                <td>{playerGearStats && playerGearStats.defence_ranged !== null ? playerGearStats.defence_ranged : '?'}</td>
              </tr>
              <tr className='stats'>
                <td></td>
                <td></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/prayer.png'} alt='Prayer bonus' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/strength.png'} alt='Strength bonus' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/magic_strength.png'} alt='Magic Strength' /></td>
                <td><img className='table' src={Config.cdnBaseUrl + 'sprites/range_strength.png'} alt='Range Strength' /></td>
              </tr>
              <tr className='stats'>
                <td className='nobackground'></td>
                <td className='nobackground'></td>
                <td>{playerGearStats && playerGearStats.prayer !== null ? playerGearStats.prayer : '?'}</td>
                <td>{playerGearStats && playerGearStats.melee_strength !== null ? playerGearStats.melee_strength : '?'}</td>
                <td>{playerGearStats && playerGearStats.magic_damage !== null ? playerGearStats.magic_damage : '?'}</td>
                <td>{playerGearStats && playerGearStats.ranged_strength !== null ? playerGearStats.ranged_strength : '?'}</td>
              </tr>
              <tr className='stats'>
                <td colSpan='2'>Gear Value</td>
                <td className='textRight' colSpan='4'>{helpers.getGearValue(gear)}</td>
              </tr>
            </tbody>
          </Table>
        </>
      );
    }
  }
}