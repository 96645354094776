import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import Config from '../../common/config';

import Loading from '../../components/core/loading';
import NotFound from '../../components/core/notfound';

export default class MostDeaths extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false
    };
  }

  componentDidMount() {
    fetch(Config.apiBaseUrl + 'pks/mostdeaths/weekly')
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            mostDeaths: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, mostDeaths } = this.state;
    if (error) {
      return <NotFound message={error.message} />
    } else if (!isLoaded) {
      return <Loading />;
    } else {
      return (
        <>
          <h4>Most Deaths</h4>
          <Table className='smalltable'>
            <thead>
              <tr>
                <th>Player</th>
                <th className='textRight'>Deaths</th>
              </tr>
            </thead>
            <tbody>
              {mostDeaths.map(victim => (
                <tr key={victim.name}>
                  <td>
                    <Link to={`/players/${victim.name}`}>{victim.name}</Link>
                  </td>
                  <td className='textRight'>{victim.number}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      );
    }
  }
}