import Config from './config';

export function scrollToTopOfPage(){
  window.scrollTo(0, 0);
}

export function getKillUrl(id) {
  return Config.apiBaseUrl + 'pks/' + id;
}

export function getPlayerUrl(playerName) {
  return Config.apiBaseUrl + 'player/' + encodeURIComponent(playerName);
}

export function isDeadmanKill(playerKill) {
  return playerKill.worldType != null && playerKill.worldType.includes("DEADMAN");
}

export function getFormattedTime(time) {
  let newDate = new Date(time);
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();

  if (hours === '0') {
    hours = '00';
  } else if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes === '0') {
    minutes = '00';
  } else if (minutes < 10) {
    minutes = '0' + minutes;
  }

  return hours + ':' + minutes;
}

export function getFormattedDate(timestamp) {
  let newDate = new Date(timestamp);

  let month = newDate.toLocaleString('en-gb', { month: 'short' });
  let date = newDate.getDate();
  let year = newDate.getFullYear();

  return getDay(newDate) + ', ' + month + ' ' + date + ', ' + year;
}

export function getFormattedNumber(number) {
  return parseInt(number).toLocaleString();
}

function getDay(date) {
  let weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";

  return [weekday[date.getDay()]];
}

export function getShortFormattedNumber(inputNumber){
  let formattedNumber = inputNumber;

  if (inputNumber > 999_999_999) {
    formattedNumber = (Math.sign(inputNumber) * ((Math.abs(inputNumber)/1_000_000_000).toFixed(2))).toLocaleString() + 'bn'
  } else if (inputNumber > 999_999){
    formattedNumber = Math.sign(inputNumber) * ((Math.abs(inputNumber)/1_000_000).toFixed(2)) + 'm'
  } else if (inputNumber > 999){
    formattedNumber = Math.sign(inputNumber) * ((Math.abs(inputNumber)/1_000).toFixed(1)) + 'k'
  } else {
    formattedNumber = inputNumber + 'gp'
  }

  return formattedNumber;
}

export function getGpEfficiency(gpPked, gpLost){

}

export function getImageForItemId(itemId) {
  if (!itemId || itemId <= 0) {
    itemId = 0;
  }

  return Config.cdnBaseUrl + 'item-images/' + itemId + '.png';
}

export function getImageByIdForGearSlot(itemId, gearSlot) {
  let imageUrl = '';
  if ((!itemId || itemId <= 0) && gearSlot) {
    imageUrl = Config.cdnBaseUrl + 'sprites/' + gearSlot + '.png';
  } else {
    imageUrl = getImageForItemId(itemId);
  }

  return imageUrl;
}

export function getGearValue(gear) {
  let totalValue = 0;

  totalValue += gear.head ? gear.head.price : 0;
  totalValue += gear.cape ? gear.cape.price : 0;
  totalValue += gear.amulet ? gear.amulet.price : 0;
  totalValue += gear.weapon ? gear.weapon.price : 0;
  totalValue += gear.torso ? gear.torso.price : 0;
  totalValue += gear.shield ? gear.shield.price : 0;
  totalValue += gear.legs ? gear.legs.price : 0;
  totalValue += gear.hands ? gear.hands.price : 0;
  totalValue += gear.boots ? gear.boots.price : 0;

  return getFormattedNumber(totalValue);
}