import React from 'react';
import './loading.css';

export default class Loading extends React.Component {
    render() {
        return (
            <div className='d-flex justify-content-center'>
                <div className='d-md-block d-sm-none d-none'>
                    <div className='lds-ripple'><div></div><div></div></div>
                </div>
            </div>
        )
    }
}