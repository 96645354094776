import React from 'react';
import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom';
import Header from './components/core/header';
import Footer from './components/core/footer';
import NotFound from './components/core/notfound';
import PreLoadedImages from './components/core/preloadedimages';

import Killboard from './components/killboard/killboard';
import MostKills from './components/killboard/mostkills';
import MostDeaths from './components/killboard/mostdeaths';
import Pk from './components/pk/pk';
import PlayerKillboard from './components/player/playerkillboard';
import Leaderboard from './components/killboard/leaderboard';

import KillboardStats from './components/killboard/killboardstats';

function App() {
  return (
    <Router>
      <div className='App'>

        <Header />

        <div className='container'>

          {/*<div className="alert alert-danger row justify-content-center" role="alert">*/}
          {/*  ☠️ &nbsp; Currently working on a way to record and map loot keys to kills. &nbsp; ☠️*/}
          {/*</div>*/}

          {/*<div className="alert alert-danger row justify-content-center" role="alert">*/}
          {/*  ☠️☢️ &nbsp; <Link to={`/dmm`}>Deadman Mode Killboard</Link> &nbsp; Apocalypse! &nbsp; ☢️☠️*/}
          {/*</div>*/}

          <div className='row'>

            <div className='col col-lg-10 col-sm-12'>
              <Switch>
                {/* PKS */}
                <Route path='/pks/page/:pageNumber' component={Killboard}></Route>
                <Route path='/pks/:pkId' component={Pk}></Route>

                {/* Players */}
                <Route path='/players/:playerName/pks/page/:pageNumber' component={PlayerKillboard}></Route>
                <Route path='/players/:playerName' component={PlayerKillboard}></Route>

                {/* Leaderboard */}
                <Route path='/leaderboard/:leaderboardType/page/:pageNumber' component={Leaderboard}></Route>
                <Route path='/leaderboard/:leaderboardType' component={Leaderboard}></Route>

                {/* Location */}
                <Route path='/location/:locationName/page/:pageNumber' component={Killboard}></Route>
                <Route path='/location/:locationName' component={Killboard}></Route>

                {/* DMM */}
                <Route path='/dmm/page/:pageNumber' component={Killboard}></Route>
                <Route path='/dmm/' component={Killboard}></Route>

                {/* Root */}
                <Route path='/' exact component={Killboard}></Route>
                <Route component={NotFound}></Route>
              </Switch>
            </div>

            <div className='col col-lg-2 d-none d-md-block'>
              <div className='container'>

                <h4>Killboard Totals</h4>
                <hr className='hr-weeklyStats'/>
                <div className='row justify-content-center'>
                  <KillboardStats />
                </div>

                <h4>7 Days Stats</h4>
                <hr className='hr-weeklyStats'/>
                <div className='row justify-content-center'>
                  <MostKills />
                </div>

                <div className='row justify-content-center'>
                  <MostDeaths />
                </div>

              </div>
            </div>

          </div>
        </div>

        <Footer />
        <PreLoadedImages />
      </div>
    </Router>
  );
}

export default App;
