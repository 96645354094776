import React from 'react';

import Config from '../../common/config';

export default class PreLoadedImages extends React.Component {
  render() {
    return (
      <>
        {/* Stats */}
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/combat.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/hitpoints.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/attack.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/strength.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/defence.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/prayer.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/magic.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/range.png'} alt='' />
        {/* Gear Stats */}
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/stab.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/slash.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/crush.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/magic_strength.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/range_strength.png'} alt='' />
        {/* Non Loaded Gear Icons */}
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/head.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/cape.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/amulet.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/ammo.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/weapon.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/torso.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/shield.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/legs.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/hands.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/boots.png'} alt='' />
        <img className='hidden' src={Config.cdnBaseUrl + 'sprites/ring.png'} alt='' />
      </>
    );
  }
}