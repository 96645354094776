import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Config from "../../common/config";
import {Link} from "react-router-dom";

const PER_PAGE = 10;

function makeAndHandleRequest(query, page = 1) {
    return fetch(Config.apiBaseUrl + `players/search/${query}`)
        .then(response => response.json())
        .then((nameList) => {
            const options = nameList.map(playerName => ({
                player_url: `/players/${playerName}`,
                playerName: playerName,
            }));
            return { nameList, options };
        });
}

export default class PlayerSearch extends React.Component {
    state = {
        isLoading: false,
        options: [],
        query: '',
    };

    _cache = {};

    render() {
        return (
            <AsyncTypeahead
                {...this.state}
                id="player-search"
                labelKey="playerName"
                maxResults={PER_PAGE - 1}
                minLength={2}
                onInputChange={this._handleInputChange}
                onSearch={this._handleSearch}
                placeholder="Player Search..."
                renderMenuItemChildren={option => (
                    <Link to={option.player_url}>
                        <div key={option.id}>{option.playerName} </div>
                    </Link>
                )}
                useCache={false}
            />
        );
    }

    _handleInputChange = query => {
        this.setState({ query });
    };

    _handleSearch = query => {
        if (this._cache[query]) {
            this.setState({ options: this._cache[query].options });
            return;
        }

        makeAndHandleRequest(query).then(resp => {
            this._cache[query] = { ...resp, page: 1 };
            this.setState({
                options: resp.options,
            });
        });
    };
}