import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import Config from '../../common/config';
import * as helpers from '../../common/helpers';
import Loading from '../core/loading';
import NotFound from '../core/notfound';
import Pagination from '../../components/core/pagination';

import PlayerStats from './playerstats';

export default class PlayerKillboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      playerName: this.props.match.params.playerName,
      pageNumber: this.props.match.params.pageNumber ? this.props.match.params.pageNumber : 1,
    };
  }

  componentDidMount() {
    this.fetchNewData();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isLoaded: false,
      playerName: nextProps.match.params.playerName,
      pageNumber: nextProps.match.params.pageNumber ? nextProps.match.params.pageNumber : 1
    }, () => {
      this.fetchNewData();
    });
  }

  fetchNewData() {
    let pageNumber = this.state.pageNumber == null ? 1 : this.state.pageNumber;

    fetch(Config.apiBaseUrl + `players/${this.state.playerName}/pks/page/${pageNumber}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            kills: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      ).then(() => {
        window.scrollTo(0, 0);
      })
  }

  render() {
    const { error, isLoaded, kills, playerName, pageNumber } = this.state;

    if (error) {
      return <NotFound message={error.message} />
    } else if (!isLoaded) {
      return <Loading />;
    } else {
      return (
        <>
          <PlayerStats playerName={playerName} />
          
          <h4>{playerName}'s Recent PKs</h4>
          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Value</th>
                <th>Victim</th>
                <th className='d-none d-md-block'>Pker</th>
              </tr>
            </thead>
            <tbody>
              {kills.map((kill, index) => (
                <React.Fragment key={kill.id}>
                  {(index === 0) && (
                    <tr className='dateRow'>
                      <td colSpan='4'>{helpers.getFormattedDate(kill.time)}</td>
                    </tr>
                  )}
                  {(index !== 0) && (helpers.getFormattedDate(kills[index - 1].time) !== helpers.getFormattedDate(kill.time)) && (
                    <tr className='dateRow'>
                      <td colSpan='4'>{helpers.getFormattedDate(kill.time)}</td>
                    </tr>
                  )}


                  <tr key={kill.id} className={kill.pkerName === playerName ? 'row-green-bg' : 'row-red-bg'}>

                    <td>{helpers.getFormattedTime(kill.time)}</td>

                    <td>
                      <Link to={`/pks/${kill.id}`}>{helpers.getShortFormattedNumber(kill.lootValue)}</Link>
                    </td>

                    <td className={kill.victimName === playerName ? 'd-none d-md-block pixel-fix' : ''}>
                      <Link to={`/pks/${kill.id}`}>
                        <img
                             className='table'
                             src={helpers.getImageByIdForGearSlot(kill?.victimItemsEquipped?.weapon?.id, 'weapon')}
                             alt={kill?.victimItemsEquipped?.weapon?.name}
                             onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}}
                        />
                      </Link>
                      <Link to={`/players/${kill.victimName}`}>{kill.victimName}</Link>
                    </td>

                    <td className={kill.pkerName === playerName ? 'd-none d-md-block pixel-fix' : ''}>
                      <Link to={`/pks/${kill.id}`}>
                        <img
                             className='table'
                             src={helpers.getImageByIdForGearSlot(kill?.pkerItemsEquipped?.weapon?.id, 'weapon')}
                             alt={kill?.pkerItemsEquipped?.weapon?.name}
                             onError={(e)=>{e.target.onerror = null; e.target.src='https://cdn.osrskillboard.com/sprites/unknownitem.png'}}
                        />
                      </Link>
                      <Link to={`/players/${kill.pkerName}`}>{kill.pkerName}</Link>
                    </td>

                  </tr>

                </React.Fragment>
              ))}
            </tbody>
          </Table>

          <Pagination baseUrl={`/players/${playerName}/pks/page/`} pageNumber={pageNumber} pageCountUrl={`players/${playerName}/pks/pages/`} />
        </>
      );
    }
  }
}