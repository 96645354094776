import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import PlayerSearch from './playerSearch';

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <>
        <Navbar bg='dark' variant='dark' expand='lg'>
          <div className='container'>
          <Link to={`/`}>
            <Navbar.Brand>
              <img className='header d-inline-block align-top' alt='' src='https://cdn.osrskillboard.com/assets/favicon-large.png' /> {' OSRSKillboard'}
            </Navbar.Brand>
          </Link>



            <Navbar.Toggle aria-controls='basic-navbar-nav'/>
              <Navbar.Collapse id='basic-navbar-nav'>
                <Nav className='mr-auto justify-content-end' style={{ width: "100%" }}>
                  <Link className={'nav-link'} to={`/location/Bounty Hunter`}> Bounty Hunter </Link>
                  <Nav.Link className={'d-none d-sm-none d-md-none d-lg-block'}>|</Nav.Link>
                  <Link className={'nav-link'} to={`/dmm`}> DMM </Link>
                  <Nav.Link className={'d-none d-sm-none d-md-none d-lg-block'}>|</Nav.Link>
                  <NavDropdown className={'osrs-nav-item'} title="Leaderboards" id="basic-nav-dropdown">
                    <NavDropdown.Item><Link className={'nav-link osrs-nav-dropdown-item'} to={`/leaderboard/gp`}>Leaderboard by GP</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link className={'nav-link osrs-nav-dropdown-item'} to={`/leaderboard/kills`}>Leaderboard by Kills</Link></NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link className={'d-none d-sm-none d-md-none d-lg-block'}>|</Nav.Link>
                  <Nav.Link href='https://runelite.net/plugin-hub/OSRSKillboard' target="_blank">Install the RuneLite Plugin &nbsp;&nbsp;&nbsp;</Nav.Link>
                  <PlayerSearch></PlayerSearch>
                </Nav>
              </Navbar.Collapse>
          </div>
        </Navbar>
        <br />
      </>
    );
  }
}