import React from 'react';
import { Table } from 'react-bootstrap';

import * as helpers from '../../common/helpers';
import NotFound from '../core/notfound';
import './playerinfo.css';
import {Link} from "react-router-dom";

export default class PkDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      time: this.props.time,
      world: this.props.world,
      worldType: this.props.worldType,
      worldLocation: this.props.worldLocation,
      displayLocation: this.props.displayLocation,
      lootValue: this.props.lootValue
    };
  }

  render() {
    const { error, time, world, worldType, worldLocation, displayLocation, lootValue } = this.state;

    if (error) {
      return <NotFound message={error.message} />
    } else {
      return (
        <>
          <h4>PK Details</h4>
          <Table>
            <tbody>
              <tr className='stats'>
                  <td>Time</td>
                  <td>{time ? helpers.getFormattedTime(time) : '?'}</td>
              </tr>

              <tr className='stats'>
                  <td>Date</td>
                  <td>{time ? helpers.getFormattedDate(time) : '?'}</td>
              </tr>

              <tr className='stats'>
                  <td>World</td>
                  <td>{world ? world : '?'}</td>
              </tr>
              <tr className='stats'>
                  <td>World Type</td>
                  <td>{worldType ? worldType : '?'}</td>
              </tr>
              <tr className='stats'>
                  <td>Location</td>

                  <td>{displayLocation ? <Link to={`/location/${displayLocation}`}>{displayLocation}</Link> : worldLocation}</td>
              </tr>
              <tr className='stats'>
                  <td>Loot Value</td>
                  <td>{lootValue ? helpers.getFormattedNumber(lootValue) : '?'}</td>
              </tr>
            </tbody>
          </Table>        
        </>
      );
    }
  }
}