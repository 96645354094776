import React from 'react';

import Loading from '../core/loading';
import NotFound from '../core/notfound';

import Config from '../../common/config';
import PkDetails from './pkdetails';
import PlayerInfo from './playerinfo';
import Loot from './loot';

export default class Pk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      pkId: this.props.match.params.pkId,
      pkInfo: null
    };
  }

  componentDidMount() {
    this.fetchNewData();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isLoaded: false,
      pkId: nextProps.match.params.pkId
    }, () => {
      this.fetchNewData();
    });
  }

  fetchNewData() {
    fetch(Config.apiBaseUrl + `pks/${this.state.pkId}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            pkInfo: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      ).then(() =>{
        window.scrollTo(0, 0);
      })
  }

  render() {
    const { error, isLoaded, pkInfo } = this.state;
    if (error) {
      return <NotFound message={error.message} />
    } else if (!isLoaded) {
      return <Loading />;
    }
    else {
      return (
        <>
          <div className='container'>

            <div className='row'>
              <div className='col col-xl-3 col-lg-3 col-12'>
                <PlayerInfo gear={pkInfo.victimItemsEquipped}
                  playerName={pkInfo.victimName}
                  playerCombat={pkInfo.victimCombatLevel}
                  playerStats={pkInfo.victimCombatStats}
                  playerGearStats={pkInfo.victimItemsEquippedStats}
                  isPker={false} 
                />
              </div>

              <div className='col col-xl-6 col-lg-6 col-12'>
                <PkDetails 
                    time={pkInfo.time}
                    world={pkInfo.world}
                    worldType={pkInfo.worldType}
                    worldLocation={pkInfo.worldLocation}
                    displayLocation={pkInfo.displayLocation}
                    lootValue={pkInfo.lootValue}
                  />

                <Loot loot={pkInfo.loot} lootValue={pkInfo.lootValue} />
              </div>

              <div className='col col-xl-3 col-lg-3 col-12'>
                <PlayerInfo gear={pkInfo.pkerItemsEquipped}
                  playerName={pkInfo.pkerName}
                  playerCombat={pkInfo.pkerCombatLevel}
                  playerStats={pkInfo.pkerCombatStats}
                  playerGearStats={pkInfo.pkerItemsEquippedStats}
                  isPker={true} 
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}