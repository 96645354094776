import React from 'react';
import { Table } from 'react-bootstrap';

import Config from '../../common/config';
import Loading from '../core/loading';
import NotFound from '../core/notfound';
import * as helpers from '../../common/helpers';

export default class KillboardStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      stats: null
    };
  }

  componentDidMount() {
    fetch(Config.apiBaseUrl + `kb/stats`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            stats: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, stats } = this.state;
    
    if (error) {
      return <NotFound message={error.message} />;
    } else if (!isLoaded) {
      return <Loading />;
    } else {
      return (
        <>
          <Table className='smalltable tableRight'>
            <tbody>
                <tr>
                    <td>Total Kills</td>
                    <td>{helpers.getFormattedNumber(stats.totalKills)}</td>
                </tr>
                <tr>
                    <td>Total GP</td>
                    <td>{helpers.getShortFormattedNumber(stats.totalGp)}</td>
                </tr>
            </tbody>
          </Table>
        </>
      );
    }
  }
}