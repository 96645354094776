import React from 'react';
import { Table } from 'react-bootstrap';

import Config from '../../common/config';
import * as helpers from '../../common/helpers';
import NotFound from '../core/notfound';

export default class PlayerStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      playerName: this.props.playerName,
      week: null,
      month: null,
      year: null,
      stats: null,
    };
  }

  componentDidMount() {
    this.fetchNewData();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isLoaded: false,
      playerName: nextProps.match.params.playerName
    }, () => {
      this.fetchNewData();
    });
  }

  fetchNewData() {
    Promise.all([
      fetch(Config.apiBaseUrl + `players/${this.state.playerName}/historic/week`),
      fetch(Config.apiBaseUrl + `players/${this.state.playerName}/historic/month`),
      fetch(Config.apiBaseUrl + `players/${this.state.playerName}/historic/year`),
      fetch(Config.apiBaseUrl + `players/${this.state.playerName}/stats`),
    ])
        .then(([weekResponse, monthResponse, yearResponse, statsResponse]) =>
            Promise.all([weekResponse.json(), monthResponse.json(), yearResponse.json(), statsResponse.json()]))
        .then(([weekJson, monthJson, yearJson, statsJson]) => this.setState({
              isLoaded: true,
              week: weekJson,
              month: monthJson,
              year: yearJson,
              stats: statsJson,
            }),
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            })
        .then(() => {
          window.scrollTo(0, 0);
        });
  }

  render() {
    const { error, isLoaded, playerName, week, month, year, stats } = this.state;
    if (error) {
      return <NotFound message={error.message} />
    } else if (!isLoaded) {
      return (
          <>
            <h4>{playerName}'s Stats</h4>
            <Table className='tableRight'>
              <thead>
              <tr>
                <th></th>
                <th>Weekly</th>
                <th className='d-none d-sm-table-cell'>Monthly</th>
                <th className='d-none d-sm-table-cell'>Yearly</th>
                <th>All Time</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Kills</td>
                <td>0</td>
                <td className='d-none d-sm-table-cell'>0</td>
                <td className='d-none d-sm-table-cell'>0</td>
                <td>0</td>
              </tr>

              <tr>
                <td>Deaths</td>
                <td>0</td>
                <td className='d-none d-sm-table-cell'>0</td>
                <td className='d-none d-sm-table-cell'>0</td>
                <td>0</td>
              </tr>

              <tr>
                <td>K:D</td>
                <td>0 : 0</td>
                <td className='d-none d-sm-table-cell'>0 : 0</td>
                <td className='d-none d-sm-table-cell'>0 : 0</td>
                <td>0 : 0</td>
              </tr>

              <tr>
                <td>GP Pked</td>
                <td>0gp</td>
                <td className='d-none d-sm-table-cell'>0gp</td>
                <td className='d-none d-sm-table-cell'>0gp</td>
                <td>0gp</td>
              </tr>

              <tr>
                <td>GP Lost</td>
                <td>0gp</td>
                <td className='d-none d-sm-table-cell'>0gp</td>
                <td className='d-none d-sm-table-cell'>0gp</td>
                <td>0gp</td>
              </tr>

              <tr>
                <td>GP Efficiency</td>
                <td>0gp : 0gp</td>
                <td className='d-none d-sm-table-cell'>0gp : 0gp</td>
                <td className='d-none d-sm-table-cell'>0gp : 0gp</td>
                <td>0gp : 0gp</td>
              </tr>
              </tbody>
            </Table>
          </>
      );
    } else {
      return (
        <>
          <h4>{playerName}'s Stats</h4>
          <Table className='tableRight'>
            <thead>
              <tr>
                <th></th>
                <th>Weekly</th>
                <th className='d-none d-sm-table-cell'>Monthly</th>
                <th className='d-none d-sm-table-cell'>Yearly</th>
                <th>All Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kills</td>
                <td>{helpers.getFormattedNumber(week.kills)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getFormattedNumber(month.kills)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getFormattedNumber(year.kills)}</td>
                <td>{helpers.getFormattedNumber(stats.kills)}</td>
              </tr>

              <tr>
                <td>Deaths</td>
                <td>{helpers.getFormattedNumber(week.deaths)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getFormattedNumber(month.deaths)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getFormattedNumber(year.deaths)}</td>
                <td>{helpers.getFormattedNumber(stats.deaths)}</td>
              </tr>

              <tr>
                <td>K:D</td>
                <td>{helpers.getFormattedNumber(week.kills)} : {helpers.getFormattedNumber(week.deaths)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getFormattedNumber(month.kills)} : {helpers.getFormattedNumber(month.deaths)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getFormattedNumber(year.kills)} : {helpers.getFormattedNumber(year.deaths)}</td>
                <td>{helpers.getFormattedNumber(stats.kills)} : {helpers.getFormattedNumber(stats.deaths)}</td>
              </tr>

              <tr>
                <td>GP Pked</td>
                <td>{helpers.getShortFormattedNumber(week.killsGp)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getShortFormattedNumber(month.killsGp)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getShortFormattedNumber(year.killsGp)}</td>
                <td>{helpers.getShortFormattedNumber(stats.killsGp)}</td>
              </tr>

              <tr>
                <td>GP Lost</td>
                <td>{helpers.getShortFormattedNumber(week.deathsGp)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getShortFormattedNumber(month.deathsGp)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getShortFormattedNumber(year.deathsGp)}</td>
                <td>{helpers.getShortFormattedNumber(stats.deathsGp)}</td>
              </tr>

              <tr>
                <td>GP Efficiency</td>
                <td>{helpers.getShortFormattedNumber(week.killsGp)} : {helpers.getShortFormattedNumber(week.deathsGp)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getShortFormattedNumber(month.killsGp)} : {helpers.getShortFormattedNumber(month.deathsGp)}</td>
                <td className='d-none d-sm-table-cell'>{helpers.getShortFormattedNumber(year.killsGp)} : {helpers.getShortFormattedNumber(year.deathsGp)}</td>
                <td>{helpers.getShortFormattedNumber(stats.killsGp)} : {helpers.getShortFormattedNumber(stats.deathsGp)}</td>
              </tr>
            </tbody>
          </Table>
        </>
      );
    }
  }
}