import React from 'react';

export default class Footer extends React.Component {
  render() {
    let fullYear = new Date().getFullYear();
    return (
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <footer className='footer bg-dark page-footer font-small'>
          <div className='container'>
            <div className='txt-grey text-center py-2'>
              RuneScape and Old School Runescape are trademarks of Jagex Ltd.
            <br />Copyright © {fullYear} osrskillboard.com, All Rights Reserved. Inspired by Squizz @ <a className='inline' href='https://zkillboard.com/' target='_blank' rel='noopener noreferrer'>zkillboard.com</a>
            </div>
          </div>
        </footer>
      </>
    );
  }
}